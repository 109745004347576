<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      no-click-animation
      max-width="550px"
    >
      <v-card>
        <v-card-title class="headline pa-4" v-t="'settings'"></v-card-title>

        <v-divider />

        <v-card-text class="pa-4">
          <v-select
            :label="$t('language')"
            v-model="selectedLanguage"
            :items="languages"
            :menu-props="{ maxHeight: 'auto' }"
          >
            <template slot="selection" slot-scope="data">
              <v-list-item-content class="py-2">
                <v-list-item-title
                  v-text="`${data.item.text}`"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-t="'ok'"
            @click="showDialog = false"
            depressed
            color="primary"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar style="position: absolute" :value="recognizing" :timeout="-1">
      <v-btn
        @click="recognitionStop"
        class="mx-0 py-1"
        style="width: 100%"
        color="grey darken-2"
        depressed
      >
        <span v-t="'stop_recognition'"></span>
        <v-icon v-text="icons.mdiMicrophone" />
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { logger } from "@/helpers";
import { languageText } from "@/i18n";

import { mdiMicrophone } from "@mdi/js";

export default {
  name: "speech-recognition",

  props: {
    language: String
  },

  data() {
    return {
      showDialog: false,

      recognizing: false,
      recognition: null,

      selectedLanguage: "en-US",
      languages: [
        "en-US",
        "sv-SE",
        "nb-NO",
        "bg-BG",
        "da-DK",
        "fi-FI",
        "nl-NL",
        "de-DE",
        "pt-PT",
        "es-ES",
        "fr-FR"
      ].map(value => {
        const languageCode = value.split(/[-_]/)[0];
        return { value, text: languageText({ languageCode }) };
      }),
      icons: {
        mdiMicrophone
      }
    };
  },

  methods: {
    showSettings() {
      this.showDialog = true;
    },

    recognitionInit(element) {
      const SpeechRecog =
        window.SpeechRecognition || window.webkitSpeechRecognition;

      this.recognition = new SpeechRecog();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;

      this.recognition.onstart = () => {
        logger.log("recognition begun", this.recognition.lang);
        this.recognizing = true;
      };

      const nodeCleanup = () => {
        const node = element.querySelector(".speech-to-text");
        if (node) {
          node.removeAttribute("class");
          const final = node.querySelector(".final");
          if (final) {
            node.innerHTML = final.innerHTML;
          }
        }
      };

      this.recognition.onend = () => {
        logger.log("recognition stopped");
        this.recognizing = false;
        nodeCleanup();
      };

      this.recognition.onerror = err => {
        logger.warn("recognition error:", err);
        this.recognizing = false;
        nodeCleanup();
      };

      this.recognition.onresult = event => {
        const span = element.querySelector(".speech-to-text");
        if (!span) {
          this.recognitionStop();
          return;
        }

        let final = span.querySelector(".final");
        let interim;

        if (!final) {
          final = document.createElement("span");
          final.setAttribute("class", "final");

          interim = document.createElement("span");
          interim.setAttribute("class", "interim");

          span.appendChild(final);
          span.appendChild(interim);
        } else {
          interim = span.querySelector(".interim");
        }

        interim.innerText = "";

        // eslint-disable-next-line
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            final.innerHTML += event.results[i][0].transcript.replace(
              /\n+/,
              "<br>"
            );
          } else {
            interim.innerText += event.results[i][0].transcript;
          }
        }
      };
    },

    recognitionStart(editor) {
      if (this.recognizing) {
        this.recognitionStop();
      }

      if (this.recognition === null) {
        this.recognitionInit(editor.el);
      }

      editor.format.apply("span", { class: "speech-to-text" });
      this.recognition.lang = this.selectedLanguage;
      this.recognition.start();
    },

    recognitionStop() {
      if (this.recognition !== null) {
        this.recognition.stop();
      }
    }
  },

  created() {
    if (this.languages.map(item => item.value).includes(this.language)) {
      this.selectedLanguage = this.language;
    }
  }
};
</script>
