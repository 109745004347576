<template>
  <div
    :class="{
      'froala-div': !fullscreen,
      'froala-fs': fullscreen,
      'layout fill-height': true
    }"
  >
    <div
      ref="editor"
      :tag="'div'"
      :class="{
        'module-editor-wrap': fullscreen && $vuetify.breakpoint.smAndDown,
        'wrapper-a4': fullscreen && !$vuetify.breakpoint.smAndDown,
        'fr-box fr-basic': true
      }"
    ></div>

    <v-layout
      v-if="!fullscreen || !$vuetify.breakpoint.smAndDown"
      :class="{
        'word-count': true,
        'error--text': maxWords && numWords > maxWords
      }"
    >
      <span
        v-t="{
          path: 'detailed_word_count',
          args: {
            numWords:
              maxWords && numWords > maxWords
                ? `${numWords}/${maxWords}`
                : numWords,
            numChars: numChars
          }
        }"
      ></span>
    </v-layout>

    <v-snackbar
      v-model="wordLimitWarning"
      :timeout="-1"
      multi-line
      color="error"
    >
      <span v-t="{ path: 'word_limit', args: { num: maxWords } }"></span>
      <v-btn @click="wordLimitWarning = false" text class="ml-2">
        <span v-t="'ok'"></span>
      </v-btn>
    </v-snackbar>

    <speech-recognition
      v-if="!!speechRecognitionLanguage"
      ref="speech_recognition"
      :language="speechRecognitionLanguage"
    />

    <v-dialog
      v-model="spellCheckLanguageDialog"
      max-width="550px"
      persistent
      no-click-animation
      scrollable
    >
      <v-card>
        <v-card-title class="headline pa-4" v-t="'spell_checker'" />

        <v-divider />

        <v-card-text class="pa-4 px-1">
          <v-select
            :label="$t('language')"
            v-model="selectedSpellCheckLanguage"
            :items="spellCheckLanguages"
            class="px-3"
          />

          <template>
            <v-list>
              <v-subheader>
                {{ $t("ignored_words") }} ({{ ignoredWords.length }})
              </v-subheader>
              <v-list-item
                v-for="word in ignoredWords"
                :key="word"
                class="highlight-item"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="word" />
                </v-list-item-content>
                <v-list-item-action class="my-0">
                  <v-btn @click="removeIgnoredWord(word)" icon>
                    <v-icon
                      color="grey darken-3"
                      center
                      v-text="icons.mdiDelete"
                    />
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            color="primary"
            @click="spellCheckLanguageDialog = false"
          >
            <span v-t="'ok'"></span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="wordCountDialog"
      max-width="550px"
      persistent
      no-click-animation
      scrollable
    >
      <v-card>
        <v-card-title class="headline pa-4" v-t="'word_count'" />

        <v-divider />

        <v-card-text class="pa-4">
          <span
            :class="{ 'error--text': maxWords && numWords > maxWords }"
            v-t="{
              path: 'detailed_word_count',
              args: {
                numWords:
                  maxWords && numWords > maxWords
                    ? `${numWords}/${maxWords}`
                    : numWords,
                numChars: numChars
              }
            }"
          ></span>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn depressed color="primary" @click="wordCountDialog = false">
            <span v-t="'ok'"></span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import speechRecognition from "@/components/speechRecognition.vue";

import FroalaEditor from "@/plugins/froala";
import { defaultConfig } from "@/plugins/froala";
import { languageText } from "@/i18n";
import { mdiCloudOffOutline, mdiSync, mdiCloudCheck, mdiDelete } from "@mdi/js";
import { logger } from "@/helpers";
import messaging from "@/plugins/messaging";

export default {
  name: "extendedTextInteraction",

  components: {
    speechRecognition
  },

  props: {
    value: String,
    fullscreen: { type: Boolean, default: false },
    maxWords: Number
  },

  inject: ["assessmentItemId", "interactionInitialized"],
  data() {
    return {
      numBytes: 0,
      numWords: 0,
      numChars: 0,

      wordLimitWarning: false,

      wordCountDialog: false,

      speechSynthesisDialog: false,
      speechRecognize: false,

      spellCheckLanguageDialog: false,
      ignoredWords: [],
      selectedSpellCheckLanguage: "NOT_SET",
      config: null,

      icons: {
        mdiCloudOffOutline,
        mdiSync,
        mdiCloudCheck,
        mdiDelete
      },

      spellCheckLanguages: [
        {
          value: "en_US",
          text: languageText({ languageCode: "en", regionCode: "us" })
        },
        {
          value: "sv_SE",
          text: languageText({ languageCode: "sv" })
        },
        {
          value: "nn_NO",
          text: languageText({ languageCode: "no", regionCode: "nn" })
        },
        {
          value: "nb_NO",
          text: languageText({ languageCode: "no", regionCode: "nb" })
        },
        {
          value: "bg_BG",
          text: languageText({ languageCode: "bg" })
        },
        {
          value: "nl_NL",
          text: languageText({ languageCode: "nl" })
        },
        {
          value: "da_DK",
          text: languageText({ languageCode: "da" })
        },
        {
          value: "es_ES",
          text: languageText({ languageCode: "es" })
        },
        {
          value: "pt_PT",
          text: languageText({ languageCode: "pt" })
        },
        {
          value: "pt_BR",
          text: languageText({ languageCode: "pt", regionCode: "br" })
        },
        {
          value: "de_DE",
          text: languageText({ languageCode: "de" })
        },
        {
          value: "en_GB",
          text: languageText({ languageCode: "en", regionCode: "gb" })
        },
        {
          value: "en_CA",
          text: languageText({ languageCode: "en", regionCode: "ca" })
        },
        {
          value: "fi_FI",
          text: languageText({ languageCode: "fi" })
        },
        {
          value: "fr_FR",
          text: languageText({ languageCode: "fr" })
        },
        {
          value: "fr_CA",
          text: languageText({ languageCode: "fr", regionCode: "ca" })
        },
        {
          value: "el_GR",
          text: languageText({ languageCode: "el" })
        },
        {
          value: "it_IT",
          text: languageText({ languageCode: "it" })
        },
        {
          value: "ru_RU",
          text: languageText({ languageCode: "ru" })
        }
      ]
    };
  },

  computed: {
    readOnly() {
      return this.$store.getters.readOnly || this.$store.getters.revisionDialog;
    },
    speechSynthesis() {
      return this.$store.getters.speechSynthesis;
    },

    speechRecognitionLanguage() {
      return this.$store.getters.speechRecognitionLanguage;
    },

    spellCheckLanguage() {
      return this.$store.getters.spellCheckLanguage;
    },
    allowPrint() {
      return this.$store.getters.allowPrint;
    },
    moduleType() {
      return this.$store.getters.moduleType;
    },

    hidden() {
      return this.$store.getters.hidden;
    },

    hiddenInit() {
      return this.$store.getters.hiddenInit;
    }
  },

  methods: {
    updateReadonly() {
      if (this.editor && !this.editor.destroying) {
        if (this.readOnly) {
          this.editor.edit.off();
        } else {
          this.editor.edit.on();
        }
      }
    },
    froalaLang() {
      switch (this.$i18n.locale) {
        case "pt-PT":
          return "pt_pt";

        case "es-ES":
          return "es";

        case "bg-BG":
          return "bg_bg";

        case "fr-FR":
          return "fr";

        case "et-ET":
          return "et";

        case "cy-GB":
          return "gb";

        default:
          return this.$i18n.locale;
      }
    },
    async editorInitialized() {
      this.editor.el.addEventListener("spellCheckSettings", () => {
        this.ignoredWords = this.editor.spellChecker.getIgnoredWords();
        this.spellCheckLanguageDialog = true;
      });

      this.editor.el.addEventListener("spellCheckContentChanged", () => {
        this.$emit("input", this.editor.html.get());
        this.editor.undo.saveStep();
      });

      this.editor.el.addEventListener("versionHistory", this.versionHistoryBtn);

      this.editor.el.addEventListener(
        "speechSynthesis",
        this.speechSynthesisBtn
      );

      this.editor.el.addEventListener(
        "speechRecognition",
        this.speechRecognitionBtn
      );

      this.editor.el.addEventListener("print", () => {
        messaging.syncMessage("PRINT");
      });

      this.editor.el.addEventListener("wordCounter", () => {
        this.wordCountDialog = true;
      });

      this.editor.html.set(this.value || "");

      this.$el.querySelector(".fr-element").style.visibility = "visible";

      this.updateReadonly();
      this.editor.undo.saveStep();
      this.interactionInitialized();
    },

    editorContentChanged(noEmit) {
      if (!this.editor) return;
      this.numBytes = this.editor.el.innerHTML.length;
      this.numChars = this.editor.el.innerText.replace(/\s+/g, "").length;
      this.numWords =
        this.numChars === 0
          ? 0
          : this.editor.el.innerText.trim().split(/\s+/).length;
      if (this.$store.getters.moduleType === "qti_editor") {
        this.$store.commit("setEditorMetadata", {
          metadata: { word_count: this.numWords, char_count: this.numChars }
        });
      }

      if (!noEmit) {
        this.$emit("input", this.editor.html.get());
      }
    },

    validate() {
      return this.numChars > 0;
    },

    versionHistoryBtn(event) {
      // stop bubbling upwards
      event.stopPropagation();
      if (document.activeElement) {
        try {
          // try to blur so editorContentChanged can be triggered before we
          // set read only.
          document.activeElement.blur();
        } catch (error) {
          logger.warn(error);
        }
      }

      this.$store.commit("setRevisionDialog", true);
    },

    speechRecognitionBtn(event) {
      // stop bubbling upwards
      event.stopPropagation();

      if (event && event.detail === "opts") {
        this.$refs.speech_recognition.showSettings();
      } else if (event && event.detail === "speak") {
        this.$refs.speech_recognition.recognitionStart(this.editor);
      }
    },

    removeIgnoredWord(word) {
      this.ignoredWords.splice(this.ignoredWords.indexOf(word), 1);
      this.editor.spellChecker.removeIgnoredWord(word);
    },
    rescaleEditorHeight() {
      const top = this.$refs["editor"].querySelector(".fr-toolbar").offsetTop;
      const offset = window.innerHeight - 65 - top;
      this.editor.opts.heightMin = Math.max(offset, 120);
      this.editor.size.refresh();
    }
  },

  watch: {
    value(v) {
      if (!this.editor) return;
      if (JSON.stringify(v) == JSON.stringify(this.editor.html.get())) return;
      this.editor.html.set(v);
      this.editor.undo.reset();
      this.editor.undo.saveStep();
      this.editorContentChanged(true);
      if (this.editor.spellChecker) {
        this.editor.spellChecker.spellCheck();
      }
    },
    readOnly() {
      this.updateReadonly();
    },

    selectedSpellCheckLanguage(v, oldValue) {
      if (oldValue === "NOT_SET") return;

      if (this.editor && this.editor.spellChecker) {
        this.editor.spellChecker.changeLanguage(v);
      }
    },

    numWords(n1, n2) {
      if (!this.maxWords || !n1) return;

      if ((!n2 || n2 <= this.maxWords) && n1 > this.maxWords) {
        this.wordLimitWarning = true;
      }
    },

    hidden() {
      if (!this.hidden && this.editor) {
        setTimeout(() => {
          if (this.editor.spellChecker.isEnabled()) {
            this.editor.spellChecker.spellCheck();
          }
          this.editorContentChanged(true);
          if (this.fullscreen && this.$vuetify.breakpoint.smAndDown) {
            this.rescaleEditorHeight();
          }
        }, 0);
      }
    },

    $route(to) {
      if (to.query.itemId === this.assessmentItemId) {
        if (this.editor) {
          this.$nextTick(() => {
            if (this.editor.spellChecker.isEnabled()) {
              this.editor.spellChecker.spellCheck();
            }
            this.editorContentChanged();
          });
        }
      }
    }
  },
  beforeDestroy: function() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },
  mounted() {
    this.selectedSpellCheckLanguage = this.spellCheckLanguage;

    const ctx = this;
    this.config = {
      ...defaultConfig(),
      language: this.froalaLang(),
      spellCheckLanguage: this.spellCheckLanguage,

      events: {
        initialized() {
          ctx.editor = this;
          ctx.$nextTick(ctx.editorInitialized);
        },
        "commands.after": function(cmd) {
          if (
            ctx.fullscreen &&
            ctx.$vuetify.breakpoint.smAndDown &&
            ["moreMisc", "moreParagraph", "moreRich", "moreText"].includes(cmd)
          ) {
            ctx.rescaleEditorHeight();
          }
        },
        contentChanged() {
          ctx.editorContentChanged();
        }
      }
    };

    const miscButtons = [];
    if (
      this.spellCheckLanguage ||
      this.speechSynthesis ||
      this.speechRecognitionLanguage
    ) {
      if (this.speechSynthesis) {
        miscButtons.push("speechSynthesis");
      }
      if (
        this.speechRecognitionLanguage &&
        (window.SpeechRecognition || window.webkitSpeechRecognition) &&
        /Chrom[^/]+\/\d+/.test(window.navigator.userAgent)
      ) {
        miscButtons.push("speechRecognition");
      }
      if (this.spellCheckLanguage) {
        miscButtons.push("spellChecker");
      }
    }

    if (this.fullscreen) {
      miscButtons.push("zoom");
      this.config.toolbarButtonsSM.moreParagraph.buttonsVisible = 3;
      this.config.toolbarButtons.moreParagraph.buttonsVisible = 3;
      this.config.toolbarButtonsSM.moreText.buttonsVisible = 3;
      this.config.toolbarButtons.moreText.buttonsVisible = 3;
      this.config.toolbarButtonsSM.moreMisc.buttonsVisible = 6;
      this.config.toolbarButtons.moreMisc.buttonsVisible = 6;
      this.config.heightMin = Math.max(window.innerHeight - 65, 120);

      window.addEventListener("resize", () => {
        if (this.editor && this.editor.spellChecker.isEnabled()) {
          this.editor.spellChecker.spellCheck();
        }
      });
    } else {
      this.config.heightMin = 120;
    }

    if (this.allowPrint) {
      miscButtons.push("print");
    }
    this.config.toolbarButtons.moreMisc.buttons.splice(3, 0, ...miscButtons);
    this.config.toolbarButtonsSM.moreMisc.buttons.splice(3, 0, ...miscButtons);
    this.config.toolbarButtonsXS.moreMisc.buttons.splice(3, 0, ...miscButtons);

    this.editor = new FroalaEditor(this.$refs["editor"], this.config);
  }
};
</script>

<style>
.word-count {
  z-index: 2;
  position: absolute;
  bottom: 2px;
  right: 26px;
  font-size: 12px;
  user-select: none;
}
.froala-fs .word-count {
  position: fixed;
}

.fr-toolbar .fr-command.fr-btn svg path {
  fill: currentColor;
}

.froala-div .fr-second-toolbar,
.fr-toolbar,
.fr-box.fr-basic .fr-wrapper {
  border: 0px;
}
.froala-fs .fr-second-toolbar {
  border: 0px;
  background: none;
}
.wrapper-a4 .fr-wrapper {
  background: white;

  width: 8.5in;
  height: auto !important;

  border: none !important;

  overflow: visible !important;

  margin: 0 auto;
  margin-top: 18px;
  margin-bottom: 32px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
}

.wrapper-a4 .fr-element {
  width: 8.5in;

  margin: 0px !important;
  padding: 96px !important;
  min-height: 24px !important;

  line-height: 1.25em !important;
  font-size: 11pt !important;
}

.fr-box {
  display: block;

  width: 100%;

  border-radius: 0px !important;

  border: none;
  box-shadow: none;
}

.froala-fs .fr-box.fr-toolbar-open {
  margin-top: 97px;
}
.froala-fs .fr-box {
  background-color: #fafafa;
  /* padding for the fr-toolbar at the top */
  margin-top: 49px;
}

.fr-toolbar.fr-toolbar-open {
  margin-top: 48px;
}

.fr-toolbar .fr-command.fr-btn svg {
  height: 20px;
}

.fr-toolbar .fr-command.fr-btn.fr-open {
  margin: 4px 2px !important;
}

.fr-toolbar .fr-more-toolbar {
  transition: none !important;
  position: absolute !important;
  top: -48px !important;
}

.fr-box .fr-toolbar {
  height: 49px;

  border-radius: 0px;

  border-top: 0px;
  border-right: 0px;
  border-bottom: 1px solid #ccc;
  border-left: 0px;

  top: 0px !important;
  bottom: unset !important;

  z-index: 2;
}

.froala-fs .fr-box .fr-toolbar {
  position: fixed !important;

  height: 49px;
  width: 100%;
  left: 0px;
}

.module-editor-wrap .fr-wrapper {
  width: 100%;
  min-height: 100%;
  padding: 8px;
  border: none !important;
  box-shadow: none !important;
}

.fr-box.fr-basic .fr-element {
  font-size: 16px;

  font-family: Arial !important;
  color: #000000 !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-decoration: none !important;

  overflow-wrap: break-word !important;
}

.module-editor-wrap .fr-element {
  max-width: 850px;
}

.module-editor-wrap {
  background: white !important;
}

.fr-second-toolbar {
  display: none !important;
  border: none !important;
}

.fr-newline {
  display: none !important;
}

/* Froala editor content styling */

.fr-element p,
.fr-element span {
  line-height: 1.25em;
  margin: 0px;
}

.fr-element table td.fr-selected-cell,
.fr-element table th.fr-selected-cell {
  border: 1px double #1e88e5 !important;
}
div.spell-check-container {
  position: relative;

  width: 0px;
  height: 0px;

  padding: 0px;
  margin: 0px;

  pointer-events: none;

  z-index: 20;
}

div.spell-check-contextmenu {
  display: none;
  position: absolute;

  padding: 3px 0px;
  background-color: white;
  border: 1px solid #eee;
  user-select: none;

  z-index: 20;
}

span.spell-check {
  border-bottom: 1px solid red;
  position: absolute;
  z-index: 20;
}

span.spell-check-hover {
  background-color: rgba(255, 0, 0, 0.1);
}

div.spell-check-contextmenu hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 3px 0px;
}

div.spell-check-contextmenu ul {
  padding: 0px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

div.spell-check-contextmenu li {
  list-style: none;
  padding: 4px 16px;
  min-width: 200px;
}

div.spell-check-contextmenu li:not(.no-suggestion) {
  cursor: pointer;
}

div.spell-check-contextmenu ul li:hover:not(.no-suggestion) {
  background-color: #ccc;
}
</style>
